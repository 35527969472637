import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../utils/typography"
import Layout from "../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

class RepairRankJaPage extends React.Component {
    componentDidMount() {

    }

    render() {
        return (
        <Layout data={this.props.data} location={this.props.location}>
            <Helmet>
            <title>修繕ランク │ Cookin’Burger</title>
            <meta name="description" content="PlayMining プラットフォーム新タイトル! マルチタスク料理ゲーム!" />
            <meta name="keywords” content=”DEA,DEAP,DEP,blockchain,ブロックチェーン,PlayMining" />
            <meta property="og:image" content="https://cookinburger.com/assets/img/ogp.png" />
            <meta property="og:title" content="修繕ランク │ Cookin’Burger" />
            <meta property="og:description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin&#x27; Burger」!" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content="修繕ランク │ Cookin’Burger" />
            <meta name="twitter:description" content="誰でも簡単に遊べるマルチタスクゲーム「Cookin&#x27; Burger」!" />
            <meta name="twitter:image" content="https://cookinburger.com/assets/img/ogp.png" />
            <noscript>{`
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1487009848391690&ev=PageView&noscript=1"/>`}
            </noscript>
            <meta name="facebook-domain-verification" content="f4x8mi0ic9i3xuu2qj1vij2jsld4pk" />
            </Helmet>
            <div className="l-main">
              <div className="section-inner_m">
                <div
                  className="breadcrumb-list wow fadeInUp"
                  data-wow-delay="0.1s"
                  data-wow-duration="0.7s"
                  data-wow-offset="0"
                >
                  <ul>
                    <li>
                      <Link to={`/en`}>TOP</Link>
                    </li>
                    <li>修繕ランク</li>
                  </ul>
                </div>
              </div>

              <div className="l-about-section">
                <div className="section-inner_m">

                  <div className="l-about-section-box">
                    <div data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                    className="l-about-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                      <img src="../../assets/img-ja/title-03.png" alt="About Repair Ranks" />
                    </div>

                    <p data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                    className="l-about-section-cap wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                      ショップNFTの耐久値を修繕することで修繕値が増加し修繕ランクが上昇していきます。<br />
                      修繕ランクによって耐久値1当たりの修繕に必要な費用が変わります。<br />修繕ランクはショップNFTの種類によって異なります。
                    </p>

                    <ul className="l-about-section-box-list">
                      <Link to={`/ja/repairrank/foodcart-sh/`}>
                      <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                        className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                          屋台中古
                      </li>
                      </Link>
                      <Link to={`/ja/repairrank/foodcart-ec/`}>
                      <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                        className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                          屋台エコノミー
                      </li>
                      </Link>
                      <Link to={`/ja/repairrank/foodcart-dx/`}>
                      <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                        className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                          屋台デラックス
                      </li>
                      </Link>
                      <Link to={`/ja/repairrank/foodtruck-sh/`}>
                      <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                        className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                          トラック中古
                      </li>
                      </Link>
                      <Link to={`/ja/repairrank/foodtruck-ec/`}>
                      <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                        className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                          トラックエコノミー
                      </li>
                      </Link>
                      <Link to={`/ja/repairrank/foodtruck-dx/`}>
                      <li data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                        className="wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                          トラック デラックス
                      </li>
                      </Link>
                        {/* <a href="foodcart-sh/"><li>Food Cart Second hand</li></a>
                        <a href="foodcart-ec/"><li>Food Cart Economy</li></a>
                        <a href="foodcart-dx/"><li>Food Cart Deluxe</li></a>
                        <a href="foodtruck-sh/"><li>Food Trucks Second hand</li></a>
                        <a href="foodtruck-ec/"><li>Food Trucks Economy</li></a>
                        <a href="foodtruck-dx/"><li>Food Trucks Deluxe</li></a> */}
                    </ul>
                  </div>

                  <ul data-sal="slide-up" data-sal-delay="200" data-sal-duration="700" data-sal-easing="ease"
                  className="l-footer-sns-list wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                      <li><a href="https://discord.gg/xWeHGdt" target="_blank"><img src="../../assets/img/sns-ico-dc.png" alt="" /></a></li>
                      <li><a href="https://twitter.com/PlayMining_SG" target="_blank"><img src="../../assets/img/sns-ico-tw.png" alt="" /></a></li>
                      <li><a href="https://www.facebook.com/PlayMining/" target="_blank"><img src="../../assets/img/sns-ico-fb.png" alt="" /></a></li>
                      <li><a href="https://t.me/DEAPcoin_group" target="_blank"><img src="../../assets/img/sns-ico-tg.png" alt="" /></a></li>
                      <li><a href="https://www.youtube.com/channel/UCGWmK0RLV4SB_PSXpj2j6dw" target="_blank"><img src="../../assets/img/sns-ico-yt.png" alt="" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
        </Layout>
        )
    }
}

RepairRankJaPage.propTypes = propTypes

export default RepairRankJaPage

export const pageQuery = graphql`
  query RepairRankJaQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`
